export const messages = {
  de: {
    sales: {
      update_title: 'Eine Aktualisierung steht aus!',
      update_description: 'Möchten Sie die Aktualisierung jetzt ausführen?! Sie können die Aktualisierung jederzeit manuell anstoßen.',
      update_do: 'Ausführen',
      update_skip: 'Überspringen',
    },
    settings: {
      global: 'Allgemein',
      salesapp: 'Sales App',
      mypoly: 'My Polyclip',
      resync: 'Erneute Synchronisation',
      resync_desc: 'Wenn Sie diese Aktion ausführen werden alle offline gespeicherten Daten gelöscht und erneut heruntergeladen.',
      resync_button: 'Neu synchronisieren',
      language: 'Sprachwechsel',
      language_desc: 'Hier können Sie die aktuelle Sprache für die Applikation einstellen.',
      password: 'Passwort ändern',
      password_desc: 'Hier können Sie Ihr aktuelles Passwort ändern.',
      password_button: 'Neues Passwort setzen',
    },
    company_size_options: {
      industry: 'Industrie',
      craft_midsize: 'Handwerk-Mittelstand',
    },
    salutation_options: {
      none: 'Keine',
      mrs: 'Frau',
      mr: 'Herr',
      other: 'Divers',
    },
    country_options: {
      germany: 'Deutschland',
      afghanistan: 'Afghanistan',
      egypt: 'Ägypten',
      albania: 'Abanien',
      algeria: 'Algerien',
      us_virgin_islands: 'Amerikanische Jungferninseln',
      american_samoa: 'Amerikanisch-Samoa',
      andorra: 'Andorra',
      angola: 'Angola',
      anguilla: 'Anguilla',
      antarctica: 'Antarktis',
      antigua_and_barbuda: 'Antigua und Barbuda',
      equatorial_guinea: 'Äquatorialguinea',
      argentina: 'Argentinien',
      armenia: 'Armenien',
      aruba: 'Aruba',
      azerbaijan: 'Aserbaidschan',
      ethiopia: 'Äthiopien',
      australia: 'Australien',
      bahamas: 'Bahamas',
      bahrain: 'Bahrain',
      bangladesh: 'Bangladesch',
      barbados: 'Barbados',
      belgium: 'Belgien',
      belize: 'Belize',
      benin: 'Benin',
      bermuda: 'Bermuda',
      bhutan: 'Bhutan',
      bolivia: 'Bolivien',
      bosnia_and_herzegovina: 'Bosnien und Herzegowina',
      botswana: 'Botswana',
      bouvet_island: 'Bouvetinsel',
      brazil: 'Brasilien',
      british_virgin_islands: 'Britische Jungferninseln',
      brunei_darussalam: 'Brunei Darussalam',
      bulgaria: 'Bulgarien',
      burkina_faso: 'Burkina Faso',
      burundi: 'Burundi',
      chile: 'Chile',
      china: 'China',
      cook_islands: 'Cookinseln',
      costa_rica: 'Costa Rica',
      denmark: 'Dänemark',
      dominica: 'Dominica',
      dominican_republic: 'Dominican Republic',
      djibouti: 'Dschibuti',
      equador: 'Ecuador',
      el_salvador: 'El Salvador',
      ivory_coast: 'Elfenbeinküste',
      eritrea: 'Eritrea',
      estland: 'Estland',
      falkland_islands: 'Falklandinseln',
      faroe_islands: 'Färöer-Inseln',
      fiji: 'Fidschi',
      finland: 'Finnland',
      france: 'Frankreich',
      french_guiana: 'Französisch-Guayana',
      french_polynesia: 'Französisch-Polynesien',
      gabon: 'Gabun',
      gambia: 'Gambia',
      georgia: 'Georgien',
      ghana: 'Ghana',
      gibraltar: 'Gibraltar',
      grenada: 'Grenada',
      greece: 'Griechenland',
      greenland: 'Grönland',
      great_britain: 'Großbritannien',
      guadeloupe: 'Guadeloupe',
      guam: 'Guam',
      guatemala: 'Guatemala',
      guinea: 'Guinea',
      guinea_bissau: 'Guinea-Bissau',
      guyana: 'Guyana',
      haiti: 'Haiti',
      heard_and_mcdonald_islands: 'Heard und McDonald-Inseln',
      honduras: 'Honduras',
      hongkong: 'Hongkong',
      india: 'Indien',
      indonesia: 'Indonesien',
      iraq: 'Irak',
      iran: 'Iran',
      ireland: 'Irland',
      iceland: 'Island',
      israel: 'Israel',
      italy: 'Italien',
      jamaica: 'Jamaika',
      japan: 'Japan',
      yemen: 'Jemen',
      jordan: 'Jordanien',
      cayman_islands: 'Kaimaninseln',
      cambodia: 'Kambodscha',
      cameroon: 'Kamerun',
      canada: 'Kanada',
      cape_verde: 'Kap Verde',
      kazakhstan: 'Kasachstan',
      qatar: 'Katar',
      kenya: 'Kenia',
      kyrgyzstan: 'Kirgisistan',
      kiribati: 'Kiribati',
      cocos_islands: 'Kokosinseln',
      colombia: 'Kolumbien',
      comoros: 'Komoren',
      congo_democratic_republic: 'Kongo, Demokratische Republik',
      congo_republiy: 'Kongo, Republik',
      croatia: 'Kroatien',
      cuba: 'Kuba',
      kuwait: 'Kuwait',
      laos: 'Laos',
      lesotho: 'Lesotho',
      latvia: 'Lettland',
      lebanon: 'Libanon',
      liberia: 'Liberia',
      libya: 'Libyen',
      liechtenstein: 'Liechtenstein',
      lithuania: 'Litauen',
      luxemburg: 'Luxemburg',
      macau: 'Macao',
      madagascar: 'Madagaskar',
      malawi: 'Malawi',
      malaysia: 'Malaysia',
      maldives: 'Malediven',
      mali: 'Mali',
      malta: 'Malta',
      morocco: 'Marokko',
      marshall_islands: 'Marshallinseln',
      martinique: 'Martinique',
      mauritania: 'Mauretanien',
      mauritius: 'Mauritius',
      mayotte: 'Mayotte',
      macedonia: 'Makedonien',
      mexico: 'Mexiko',
      micronesia: 'Mikronesien',
      moldova: 'Moldawien',
      monaco: 'Monaco',
      mongolia: 'Mongolei',
      montenegro: 'Montenegro',
      montserrat: 'Montserrat',
      mosambique: 'Mosambik',
      myanmar: 'Myanmar',
      namibia: 'Namibia',
      nauru: 'Nauru',
      nepal: 'Nepal',
      new_caledonia: 'Neukaledonien',
      new_zealand: 'Neuseeland',
      nicaragua: 'Nicaragua',
      netherlands: 'Niederlande',
      niger: 'Niger',
      nigeria: 'Nigeria',
      niue: 'Niue',
      north_korea: 'Nordkorea',
      northern_mariana_islands: 'Nördliche Marianen',
      norfork_island: 'Norfolkinsel',
      norway: 'Norwegen',
      oman: 'Oman',
      austria: 'Österreich',
      east_timor: 'Osttimor',
      pakistan: 'Pakistan',
      palestine: 'Palästina',
      palau: 'Palau',
      panama: 'Panama',
      papua_new_guinea: 'Papua-Neuguinea',
      paraguay: 'Paraguay',
      peru: 'Peru',
      philippines: 'Philippinen',
      pitcairn_islands: 'Pitcairn-Inseln',
      poland: 'Polen',
      portugal: 'Portugal',
      puerto_rico: 'Puerto Rico',
      reunion: 'Réunion',
      rwanda: 'Ruanda',
      romania: 'Rumänien',
      russian_federation: 'Russische Föderation',
      saint_pierre_and_miquelon: 'Saint-Pierre und Miquelon',
      solomon_islands: 'Salomonen',
      zambia: 'Sambia',
      samoa: 'Samoa',
      san_marino: 'San Marino',
      sao_tome_and_principe: 'São Tomé und Príncipe',
      saudi_arabia: 'Saudi-Arabien',
      sweden: 'Schweden',
      switzerland: 'Schweiz',
      senegal: 'Senegal',
      serbia: 'Serbien',
      seychelles: 'Seychellen',
      sierra_leone: 'Sierra Leone',
      zimbabwe: 'Simbabwe',
      singapore: 'Singapur',
      slovakia: 'Slowakei',
      slovenia: 'Slowenien',
      somalia: 'Somalia',
      spain: 'Spanien',
      sri_lanka: 'Sri Lanka',
      st_helena: 'St. Helena',
      st_kitts_and_nevis: 'St. Kitts und Nevis',
      st_lucia: 'St. Lucia',
      st_vincent_and_the_grenadines: 'St. Vincent und die Grenadinen',
      south_africa: 'Südafrika',
      sudan: 'Sudan',
      south_georgia: 'Südgeorgien',
      south_korea: 'Südkorea',
      suriname: 'Suriname',
      svalbard: 'Svalbard',
      swaziland: 'Swasiland',
      syria: 'Syrien',
      tajikistan: 'Tadschikistan',
      taiwan: 'Taiwan',
      tanzania: 'Tansania',
      thailand: 'Thailand',
      togo: 'Togo',
      tokelau: 'Tokelau',
      tonga: 'Tonga',
      trinidad_and_tobago: 'Trinidad und Tobago',
      chad: 'Tschad',
      czech_republic: 'Tschechische Republik',
      tunisia: 'Tunesien',
      turkey: 'Türkei',
      turkmenistan: 'Turkmenistan',
      turks_and_caicos_islands: 'Turks- und Caicosinseln',
      tuvalu: 'Tuvalu',
      uganda: 'Uganda',
      ukraine: 'Ukraine',
      hungary: 'Ungarn',
      uruguay: 'Uruguay',
      usa: 'USA',
      uzbekistan: 'Usbekistan',
      vanuatu: 'Vanuatu',
      vatican_city: 'Vatikanstadt',
      venezuela: 'Venezuela',
      united_arab_emirates: 'Vereinigte Arabische Emirate',
      vietnam: 'Vietnam',
      wallis_and_futuna: 'Wallis und Futuna',
      christmas_island: 'Weihnachtsinsel',
      belarus: 'Weißrussland',
      western_sahara: 'Westsahara',
      central_african_republic: 'Zentralafrikanische Republik',
      cyprus: 'Zypern',
    },
    status_options: {
      client: 'Kunde',
      interested: 'Interessent',
      consultant: 'Berater',
      dealer: 'Händler',
      supplier: 'Lieferant',
      fair: 'Messe',
      teammate: 'Mitspieler',
      ppi: 'PPI',
      press: 'Presse',
      subsidiary: 'Tochtergesellschaft',
      association: 'Verband',
      representative: 'Vertreter',
      competitor: 'Wettbewerb',
    },
    title_options: {
      none: 'Keinen',
      dr: 'Dr.',
      profdr: 'Prof. Dr.',
      department: 'Abteilung',
      subjectarea: 'Fachbereich',
    },
    follow_up_actions_options: {
      offer: 'Angebot',
      visit: 'Besuch',
      leaflet: 'Prospekt',
      call: 'Telefonat',
      test: 'Test',
      video: 'Video',
      demo: 'Vorführung',
      maintainance_service: 'Wartung & Service',
      newsletter: 'Newsletter (Messeeinladungen, Eintrittskartengutscheine, Produktinformationen)',
    },
    additional_options: {
      no_thank_you_email: 'keine Danke E-Mail',
    },
    sector_options: {
      sausage: 'Wurst',
      poultry: 'Geflügel',
      dairy: 'Molkereiprodukte',
      petfood: 'Tiernahrung',
      convenience: 'Fertiggerichte',
      food_other: 'Lebensmittel, sonstige',
      sealant: 'Dichtstoffe_Klebstoffe_Chemie',
      general: 'Allgemein',
      aquaristic: 'Aquaristik',
      bakery_products: 'Backwaren_Backmittel',
      bags_other: 'Beutelverp., sonst.',
      casing_foils_manufacturer: 'Darm-/Folienhersteller',
      dental: 'Dentalstoffe',
      seafood: 'Fischindustrie_Seafood',
      meat_other: 'Fleisch, sonstige',
      marzipan: 'Marzipan',
      mechanical_engineering: 'Maschinenbau',
      medical: 'Medizintechnik',
      fruit_vegetables: 'Obst_Gemüse',
      explosive: 'Sprengstoffe',
      tobacco: 'Tabak',
      tech_other: 'Techn. Produkte, sonstige',
      preassembly: 'Vorkonfektionierung',
    },
    errors: {
      unexpected: 'Unerwarteter Fehler!',
      invalid_input: 'Eingaben ungültig.',
      connection: 'Sie brauchen eine Internetverbindung um sich anzumelden!',
      account_disabled: 'Dieser Account wurde deaktiviert!',
      invalid_credentials: 'Die E-Mail / Passwort Kombination ist nicht richtig',
      password_expired: 'Das eingegebene Passwort ist abgelaufen. <a href="{ passwordChangeURL }" target="_blank">Hier</a> klicken zum Zurücksetzen.',
      tessa_login_failed: 'Es gab ein Problem bei der Anmeldung im Tessa DAM!',
    },
    login: {
      welcome: 'Willkommen in der Poly-clip App',
      user: 'E-Mail',
      password: 'Passwort',
      submit: 'Einloggen',
      gdpr: 'Mit der Anmeldung stimme ich den <a href="{ terms }" target="_blank">Nutzungsbedingungen</a> zu und erkläre, die <a href="{ privacyPolicy }" target="_blank">Datenschutzerklärung</a> zur Kenntnis genommen zu haben.',
      imprint: 'Impressum',
      data_privacy: 'Datenschutz',
      data_privacy_link: 'https://cdn.polyclip.com/datenschutzhinweise-vertiebs-app-august_2022.pdf',
      terms_of_use: 'Nutzungsbedingungen',
      terms_of_use_link: 'https://cdn.polyclip.com/nutzungsbedingungen-my-poly-clip-august_2022.pdf',
      languageSwitch: 'Sprache',
      forgotPassword: 'Passwort vergessen?',
    },
    loadingOverlay: {
      product_category: 'Produktkategorien werden geladen ...',
      products: ' Produkte werden geladen ...',
      download_information: 'Download-Informationen werden geladen ...',
      data: 'Daten werden geladen ...',
    },
    header: {
      file: 'Datei',
      files: 'Dateien',
      expression: 'Geben Sie hier Ihren Begriff ein',
      syncing: 'Synchronisation ...',
      lastSync: 'letzte Synchronisation',
      sync: 'Synchronisierung',
      pending: 'ausstehend',
      preparing: 'wird vorbereitet ...',
      search: 'Kein Ergebnis. Bitte versuchen Sie es erneut.',
    },
    sidebar: {
      sales: 'Sales App',
      my: 'My Poly-clip',
      other: 'Sonstige',
      switchTo: 'Wechseln zu',
      loggedIn: 'Eingeloggt als',
      logout: 'Ausloggen',
      home: 'Zuhause',
      dashboard: 'Dashboard',
      search: 'Suche',
      productFinder: 'Produktfinder',
      wishlists: 'Merklisten',
      leads: 'Leads',
      savedLeads: 'Gespeicherte Leads',
      library: 'Bibliothek',
      wishlist: 'Merkliste',
      translation: 'Übersetzungsmanager',
      clip_tip: 'Clip Tip',
      settings: 'Einstellungen',
    },
    leads: {
      completed: 'Lead abgeschlossen!',
      name: 'Leaderfassung',
      contact_creator: 'Berater',
      leadDetails: 'Leadangaben',
      company: 'Firma',
      contactPersona: 'Ansprechpartner',
      machineInterests: 'Maschineninteressen',
      additional: 'Zusätzliches',
      completion: 'Abschluss',
      saveLead: 'Lead Speichern',
      couldntSave: 'Der Lead konnte nicht abgesendet werden. Bitte füllen Sie alle Felder ordnungsgemäß aus und versuchen Sie es erneut.',
      tradeFair: 'Messe / Termin / Anlass',
      whichFair: 'Auf welcher Messe / welchem Termin / welchem Anlass befinden Sie sich?',
      visitingDay: 'Besuchstag',
      whichCompany: 'Für welche Firma ist dieser Lead?',
      industry: 'Branche',
      companySize: 'Firmengröße',
      country: 'Land',
      postal_code: 'PLZ',
      which_postal_code: 'PLZ des Firmenortes',
      salutation: 'Anrede',
      title: 'Titel',
      fName: 'Vorname',
      howFName: 'Wie lautet der Vorname?',
      lName: 'Nachname',
      howLName: 'Wie lautet der Nachname?',
      status: 'Status',
      email: 'E-Mail',
      howEmail: 'Wie lautet die E-Mail Adresse?',
      phone: 'Telefon',
      howPhone: 'Wie lautet die Telefonnummer?',
      businessCard: 'Visitenkarte',
      bcFront: 'Visitenkarte Vorderseite',
      bcBack: 'Visitenkarte Rückseite',
      priority: 'Priorität',
      attachments: 'Anhänge',
      attachment1: 'Anhang 1',
      attachment2: 'Anhang 2',
      followUp: 'Folgeaktion',
      AddAdditional: 'Weiteres',
      note: 'Notizen',
      note_placeholder: 'Bitte Anmerkungen hier angeben',
      success: '  Der Lead wurde erfolgreich abgeschlossen!',
      additionalLead: 'Weiteren Lead generieren',
      dashboard: 'Zum Dashboard',
      backTo: 'Zurück zu',
      continue: 'Weiter zu',
      finishLead: 'Lead abschließen',
      newLead: 'Neuen Lead erstellen',
      savedLeads: 'Gespeicherte Leads',
      noLeads: 'Sie haben bisher noch keine Leads gespeichert.',
      searchTerm: 'Suchbegriff',
      giveSearch: 'Geben Sie hier Ihren Begriff ein',
      delete: 'Löschen',
      editLead: 'Lead bearbeiten',
      noResult: 'Die Suche erzielte keinen Treffer!',
      smallDelete: 'löschen',
      youWant: 'Möchten Sie den Lead',
      really: 'wirklich löschen?',
      cancel: 'Abbrechen',
    },
    wishlists: {
      wishlists: 'Merklisten',
      wishlist: 'Merkliste',
      addWishlist: 'Merkliste hinzufügen',
      noWishlist: 'Sie haben bisher noch keine Merklisten angelegt.',
      delete: 'Löschen',
      save: 'Speichern',
      youWant: 'Möchten Sie die Merkliste ',
      forReal: 'wirklich löschen?',
      cancel: 'Abbrechen',
      leadTransfer: 'In Leads übertragen',
      transferAll: 'Alle für Leadübertragung auswählen',
      noProducts: 'Sie haben noch keine Produkte auf dieser Merkliste.',
      toFinder: 'Zum Produktfinder',
      toDetail: 'Zur Detailseite',
      from: 'von',
      deleteProduct: 'Möchten Sie das Produkt',
      reallyFrom: 'wirklich von',
      deleteSmall: 'löschen?',
      deletedelete: 'löschen?',
      add: 'hinzufügen',
      newWishlist: 'Neue Merkliste',
      enterName: 'Bitte geben Sie einen Namen ein.',
      toWishlist: 'Zur Merkliste',
    },
    productFinder: {
      all: 'Alle',
      optionsFor: 'Auswahl für',
      productFinder: 'Produktfinder',
      showResults: 'Ergebnisse anzeigen',
      allFilters: 'Alle Filter anzeigen',
      products: 'Produkte',
      product: 'Produkt',
      quickView: 'Schnellansicht',
      detailView: 'Detailansicht',
      filter: 'Filter',
      addWishlist: 'Zur Merkliste hinzufügen',
      toWishlist: 'zur Merkliste hinzufügen?',
      yourWishlists: 'Ihre Merklisten',
      manageWishlists: 'Merklisten verwalten',
      save: 'Speichern',
      show: 'Anzeigen',
      brochures: 'Prospekte',
      priceList: 'Preisliste',
      orderForms: 'Bestellformulare',
      videos: 'Videos',
      training: 'Training',
      other: 'Sonstiges',
      noData: 'Für diese Kategorie sind aktuell keine Dateien vorhanden.',
      width: 'Breite',
      height: 'Höhe',
      weight: 'Gewicht',
      depth: 'Tiefe',
      voltage: 'Anschlussspannung',
      power: 'Leistungsaufnahme',
      validation: 'Absicherung',
      compressedAir: 'Druckluft',
      airConsumption: 'Luftverbrauch',
      spread: 'Spreizung',
      spreadTo: 'Gesamtspreizung bis',
    },
    translation: {
      infoCode: 'Info zu Code',
      context: 'Die Übersetzungen werden in folgendem Context genutzt:',
      newEntry: 'Neuer Eintrag',
      free: 'Frei lassen für neuen Eintrag.',
      onlyTranslation: 'Der Code wird nur dann benötigt wenn, eine Übersetzung übernommen werden soll.',
      translationExists: 'Übersetzung ist vorhanden',
      noTranslation: 'Übersetzung ist nicht vorhanden',
      createNewEntry: 'Ein neuer Eintrag wird erstellt.',
      machine: 'Maschine',
      create: ' Anlegen',
      newImport: 'Neu Importieren',
      search: 'Suche',
      dropTitle: 'Dateien wählen',
      dropSubTitle: 'Dateien sollten das .csv format haben',
      dropText: 'Dateien hier rein ziehen oder drücken',
      dropList: 'Gewählte Dateien:',
      dropListRemoveAll: 'Alle entfernen',
      dropListUpload: 'Editor öffnen',
      dropErrorFormat: 'ist kein gültiges Format',
      dropErrorNoReference: 'Es wurde keine Referenzdatei ausgewählt. Der Vorgang wird abgebrochen',
      dropErrorNoTranslation: 'Es wurde keine Übersetzungsdatei ausgewählt. Der Vorgang wird abgebrochen.',
    },
    myPolyclip: {
      welcomeTo: 'Willkommen bei My Poly-clip',
      photo: 'Die hier verfügbaren Fotos, Grafiken, Videos, Präsentationen etc. dürfen für werbliche Zwecke für Produkte der Firma Poly-clip System, im Rahmen unserer',
      terms: 'Nutzungsbedingungen',
      copyright: 'verwendet werden. In Print- und Online-Veröffentlichungen müssen Fotos und Grafiken mit dem Hinweis Copyright: Poly-clip System ® versehen werden.',
      publication: 'Eine Veröffentlichung ist honorarfrei. Wir bitten bei Printmedien um die Zusendung eines Belegexemplars, bei elektronischen Medien um eine kurze Nachricht per E-Mail.',
      download: 'Mit dem Download von Dateien bestätigen Sie, dass Sie unsere',
      reading: 'gelesen haben und akzeptieren.',
      welcome: 'Willkommen bei',
      loadingInfo: 'My Poly-clip Informationen werden geladen ...',
      machine: 'Maschine',
      machineNumber: 'Maschinennr',
      language: 'Sprache',
      searchTerm: 'Suchbegriff',
      giveSearchTerm: 'Geben Sie hier Ihren Begriff ein',
      search: 'Suche',
      freeText: 'Freitextsuche',
      detail: 'Detailsuche',
      searchName: 'Suchen Sie nach einem Dateinamen',
      reset: 'Filter zurücksetzen',
      general: 'Allgemein',
      name: 'Name',
      fileType: 'Dateintyp',
      size: 'Größe',
      wishlist: 'Merkliste',
      library: 'My Poly-clip Bibliothek',
      missingPermission: 'Fehlende Berechtigung',
      files: 'Dateien',
      file: 'Datei',
      extended_filters: {
        machines: 'Maschinen',
        applications: 'Anwendungen',
        consumables: 'Verbrauchsmaterial',
        others: 'Sonstiges',
      },
    },
    dashboard_sales: {
      welcome: 'Willkommen',
    },
    formSelect: {
      pleaseChoose: 'Bitte wählen',
    },
  },


  en: {
    sales: {
      update_title: 'An update is pending!',
      update_description: 'Do you want to run the update now?! You can trigger the update manually at any time.',
      update_do: 'Execute',
      update_skip: 'Skip',
    },
    settings: {
      global: 'Global',
      salesapp: 'Sales App',
      mypoly: 'My Polyclip',
      resync: 'Re-synchronization',
      resync_desc: 'When you perform this action, all data stored offline will be deleted and downloaded again.',
      resync_button: 'Resynchronize',
      language: 'Language change',
      language_desc: 'Here you can set the current language for the application.',
      password: 'Change password',
      password_desc: 'Here you can change your current password.',
      password_button: 'Set new password',
    },
    company_size_options: {
      industry: 'Industry',
      craft_midsize: 'Craft and medium-sized businesses',
    },
    salutation_options: {
      none: 'None',
      mrs: 'Mrs',
      mr: 'Mr',
      other: 'Diverse',
    },
    country_options: {
      germany: 'Germany',
      afghanistan: 'Afghanistan',
      egypt: 'Egypt',
      albania: 'Albania',
      algeria: 'Algeria',
      us_virgin_islands: 'American Virgin Islands',
      american_samoa: 'American Samoa',
      andorra: 'Andorra',
      angola: 'Angola',
      anguilla: 'Anguilla',
      antarctica: 'Antarctica',
      antigua_and_barbuda: 'Antigua and Barbuda',
      equatorial_guinea: 'Equatorial Guinea',
      argentina: 'Argentina',
      armenia: 'Armenia',
      aruba: 'Aruba',
      azerbaijan: 'Azerbaijan',
      ethiopia: 'Ethiopia',
      australia: 'Australia',
      bahamas: 'Bahamas',
      bahrain: 'Bahrain',
      bangladesh: 'Bangladesh',
      barbados: 'Barbados',
      belgium: 'Belgium',
      belize: 'Belize',
      benin: 'Benin',
      bermuda: 'Bermuda',
      bhutan: 'Bhutan',
      bolivia: 'Bolivia',
      bosnia_and_herzegovina: 'Bosnia and Herzegovina',
      botswana: 'Botswana',
      bouvet_island: 'Bouvet Island',
      brazil: 'Brazil',
      british_virgin_islands: 'British Virgin Islands',
      brunei_darussalam: 'Brunei Darussalam',
      bulgaria: 'Bulgaria',
      burkina_faso: 'Burkina Faso',
      burundi: 'Burundi',
      chile: 'Chile',
      china: 'China',
      cook_islands: 'Cook Islands',
      costa_rica: 'Costa Rica',
      denmark: 'Denmark',
      dominica: 'Dominica',
      dominican_republic: 'Dominican Republic',
      djibouti: 'Djibouti',
      equador: 'Ecuador',
      el_salvador: 'El Salvador',
      ivory_coast: 'Ivory Coast',
      eritrea: 'Eritrea',
      estland: 'Estonia',
      falkland_islands: 'Falkland Islands',
      faroe_islands: 'Faroe Islands',
      fiji: 'Fiji',
      finland: 'Finland',
      france: 'France',
      french_guiana: 'French Guiana',
      french_polynesia: 'French Polynesia',
      gabon: 'Gabon',
      gambia: 'Gambia',
      georgia: 'Georgia',
      ghana: 'Ghana',
      gibraltar: 'Gibraltar',
      grenada: 'Grenada',
      greece: 'Greece',
      greenland: 'Greenland',
      great_britain: 'Great Britain',
      guadeloupe: 'Guadeloupe',
      guam: 'Guam',
      guatemala: 'Guatemala',
      guinea: 'Guinea',
      guinea_bissau: 'Guinea-Bissau',
      guyana: 'Guyana',
      haiti: 'Haiti',
      heard_and_mcdonald_islands: 'Heard and McDonald Islands',
      honduras: 'Honduras',
      hongkong: 'Hong Kong',
      india: 'India',
      indonesia: 'Indonesia',
      iraq: 'Iraq',
      iran: 'Iran',
      ireland: 'Ireland',
      iceland: 'Iceland',
      israel: 'Israel',
      italy: 'Italy',
      jamaica: 'Jamaica',
      japan: 'Japan',
      yemen: 'Yemen',
      jordan: 'Jordan',
      cayman_islands: 'Cayman Islands',
      cambodia: 'Cambodia',
      cameroon: 'Cameroon',
      canada: 'Canada',
      cape_verde: 'Cape Verde',
      kazakhstan: 'Kazakhstan',
      qatar: 'Qatar',
      kenya: 'Kenya',
      kyrgyzstan: 'Kyrgyzstan',
      kiribati: 'Kiribati',
      cocos_islands: 'Cocos Islands',
      colombia: 'Colombia',
      comoros: 'Comoros',
      congo_democratic_republic: 'Democratic Republic of the Congo',
      congo_republiy: 'Republic of the Congo',
      croatia: 'Croatia',
      cuba: 'Cuba',
      kuwait: 'Kuwait',
      laos: 'Laos',
      lesotho: 'Lesotho',
      latvia: 'Latvia',
      lebanon: 'Lebanon',
      liberia: 'Liberia',
      libya: 'Libya',
      liechtenstein: 'Liechtenstein',
      lithuania: 'Lithuania',
      luxemburg: 'Luxembourg',
      macau: 'Macau',
      madagascar: 'Madagascar',
      malawi: 'Malawi',
      malaysia: 'Malaysia',
      maldives: 'Maldives',
      mali: 'Mali',
      malta: 'Malta',
      morocco: 'Morocco',
      marshall_islands: 'Marshall Islands',
      martinique: 'Martinique',
      mauritania: 'Mauritania',
      mauritius: 'Mauritius',
      mayotte: 'Mayotte',
      macedonia: 'North Macedonia',
      mexico: 'Mexico',
      micronesia: 'Micronesia',
      moldova: 'Moldova',
      monaco: 'Monaco',
      mongolia: 'Mongolia',
      montenegro: 'Montenegro',
      montserrat: 'Montserrat',
      mosambique: 'Mosambique',
      myanmar: 'Myanmar',
      namibia: 'Namibia',
      nauru: 'Nauru',
      nepal: 'Nepal',
      new_caledonia: 'New Caledonia',
      new_zealand: 'New Zealand',
      nicaragua: 'Nicaragua',
      netherlands: 'Netherlands',
      niger: 'Niger',
      nigeria: 'Nigeria',
      niue: 'Niue',
      north_korea: 'North Korea',
      northern_mariana_islands: 'Northern Mariana Islands',
      norfork_island: 'Norfolk Island',
      norway: 'Norway',
      oman: 'Oman',
      austria: 'Austria',
      east_timor: 'East Timor',
      pakistan: 'Pakistan',
      palestine: 'Palestine',
      palau: 'Palau',
      panama: 'Panama',
      papua_new_guinea: 'Papua New Guinea',
      paraguay: 'Paraguay',
      peru: 'Peru',
      philippines: 'Philippines',
      pitcairn_islands: 'Pitcairn Islands',
      poland: 'Poland',
      portugal: 'Portugal',
      puerto_rico: 'Puerto Rico',
      reunion: 'Reunion',
      rwanda: 'Rwanda',
      romania: 'Romania',
      russian_federation: 'Russian Federation',
      saint_pierre_and_miquelon: 'Saint Pierre and Miquelon',
      solomon_islands: 'Solomon Islands',
      zambia: 'Zambia',
      samoa: 'Samoa',
      san_marino: 'San Marino',
      sao_tome_and_principe: 'São Tomé and Principe',
      saudi_arabia: 'Saudi Arabia',
      sweden: 'Sweden',
      switzerland: 'Switzerland',
      senegal: 'Senegal',
      serbia: 'Serbia',
      seychelles: 'Seychelles',
      sierra_leone: 'Sierra Leone',
      zimbabwe: 'Zimbabwe',
      singapore: 'Singapore',
      slovakia: 'Slovakia',
      slovenia: 'Slovenia',
      somalia: 'Somalia',
      spain: 'Spain',
      sri_lanka: 'Sri Lanka',
      st_helena: 'St. Helena',
      st_kitts_and_nevis: 'St. Kitts and Nevis',
      st_lucia: 'St. Lucia',
      st_vincent_and_the_grenadines: 'St. Vincent and the Grenadines',
      south_africa: 'South Africa',
      sudan: 'Sudan',
      south_georgia: 'South Georgia',
      south_korea: 'South Korea',
      suriname: 'Suriname',
      svalbard: 'Svalbard',
      swaziland: 'Swasiland',
      syria: 'Syria',
      tajikistan: 'Tajikistan',
      taiwan: 'Taiwan',
      tanzania: 'Tanzania',
      thailand: 'Thailand',
      togo: 'Togo',
      tokelau: 'Tokelau',
      tonga: 'Tonga',
      trinidad_and_tobago: 'Trinidad and Tobago',
      chad: 'Chad',
      czech_republic: 'Czech Republic',
      tunisia: 'Tunisia',
      turkey: 'Turkey',
      turkmenistan: 'Turkmenistan',
      turks_and_caicos_islands: 'Turks and Caicos Islands',
      tuvalu: 'Tuvalu',
      uganda: 'Uganda',
      ukraine: 'Ukraine',
      hungary: 'Hungary',
      uruguay: 'Uruguay',
      usa: 'USA',
      uzbekistan: 'Uzbekistan',
      vanuatu: 'Vanuatu',
      vatican_city: 'Vatican City',
      venezuela: 'Venezuela',
      united_arab_emirates: 'United Arab Emirates',
      vietnam: 'Vietnam',
      wallis_and_futuna: 'Wallis and Futuna',
      christmas_island: 'Christmas Island',
      belarus: 'Belarus',
      western_sahara: 'Western Sahara',
      central_african_republic: 'Central African Republic',
      cyprus: 'Cyprus',

    },
    status_options: {
      client: 'Client',
      interested: 'Interested',
      consultant: 'Consultant',
      dealer: 'Dealer',
      supplier: 'Supplier',
      fair: 'Fair',
      teammate: 'Teammate',
      ppi: 'PPI',
      press: 'Press',
      subsidiary: 'Subsidiary',
      association: 'Association',
      representative: 'Representative',
      competitor: 'Competitor',
    },
    title_options: {
      none: 'None',
      dr: 'Dr.',
      profdr: 'Prof. Dr.',
      department: 'Department',
      subjectarea: 'Subject area',
    },
    follow_up_actions_options: {
      offer: 'Offer',
      visit: 'Visit',
      leaflet: 'Leaflet',
      call: 'Call',
      test: 'Test',
      video: 'Video',
      demo: 'Demonstration',
      maintainance_service: 'Maintainance & Service',
      newsletter: 'Newsletter (trade fair invitations, admission ticket vouchers, product information)',
    },
    additional_options: {
      no_thank_you_email: 'No Thank you mail',
    },
    sector_options: {
      sausage: 'Sausage',
      poultry: 'Poultry',
      dairy: 'Dairy',
      petfood: 'Petfood',
      convenience: 'Convenience',
      food_other: 'Food, other',
      sealant: 'Sealants Adhesives Chemistry',
      general: 'General',
      aquaristic: 'Aquaristic',
      bakery_products: 'Bakery products',
      bags_other: 'bags, other',
      casing_foils_manufacturer: 'Casings-/Foilsmanufacturer',
      dental: 'Dental materials',
      seafood: 'Fish industry, Seafood',
      meat_other: 'Meat other',
      marzipan: 'Marzipan',
      mechanical_engineering: 'Mechanical engineering',
      medical: 'Medical',
      fruit_vegetables: 'Fruit, Vegetables',
      explosive: 'Explosives',
      tobacco: 'Tobacco',
      tech_other: 'Techn. products, other',
      preassembly: 'Pre-assembly',
    },
    errors: {
      unexpected: 'Unexpected error!',
      invalid_input: 'Input invalid',
      connection: 'You need an internet connection to log in!',
      account_disabled: 'This user account has been disabled!',
      invalid_credentials: 'The email/password combination is not correct',
      password_expired: 'The entered password is expired. Click <a href="{ passwordChangeURL }" target="_blank">here</a> to reset it.',
      tessa_login_failed: 'There was a problem logging into Tessa DAM with your credentials!',
    },
    login: {
      welcome: 'Welcome to the Poly-clip App',
      user: 'E-Mail',
      password: 'Password',
      submit: 'Login',
      gdpr: 'By logging in, I agree to the <a href="{ terms }" target="_blank">terms</a> of use and declare that I have taken note of the <a href="{ privacyPolicy }" target="_blank">privacy policy</a>.',
      imprint: 'Imprint',
      data_privacy: 'Data privacy',
      data_privacy_link: 'https://cdn.polyclip.com/privacy-policy-sales-app-august_2022en.pdf',
      terms_of_use: 'Terms of use',
      terms_of_use_link: 'https://cdn.polyclip.com/nutzungsbedingungen-my-poly-clip-august_2022.pdf',
      languageSwitch: 'Language',
      forgotPassword: 'Forgot password?',
    },
    header: {
      file: 'file',
      files: 'files',
      expression: 'Enter your term here',
      syncing: 'synchronization ...',
      lastSync: 'last synchronization',
      sync: 'Synchronization',
      pending: 'pending',
      preparing: 'preparing ...',
      search: 'No result found. Please try again.',
    },
    loadingOverlay: {
      product_category: 'Product categories are loading ...',
      products: 'Products are loading ...',
      download_information: 'Download information is loading ...',
      data: 'Data is loading ...',
    },
    sidebar: {
      sales: 'Sales App',
      my: 'My Poly-clip',
      other: 'Other',
      switchTo: 'Switch to',
      loggedIn: 'Logged in as',
      logout: 'Logout',
      home: 'Home',
      dashboard: 'Dashboard',
      search: 'Search',
      productFinder: 'Product finder',
      wishlists: 'Watch lists',
      leads: 'Leads',
      savedLeads: 'Saved Leads',
      library: 'Library',
      wishlist: 'Watch list',
      translation: 'Translation manager',
      clip_tip: 'Clip Tip',
      settings: 'Settings',
    },

    leads: {
      completed: 'Lead closed!',
      name: 'Leads',
      contact_creator: 'Consultant',
      leadDetails: 'Lead details',
      company: 'Company',
      contactPersona: 'Contact persona',
      machineInterests: 'Machine interests',
      additional: 'Additional',
      completion: 'Completion',
      saveLead: 'Save Lead',
      couldntSave: 'The lead could not be sent. Please fill out all fields correctly and try again.',
      tradeFair: 'Trade fair / appointment / occasion',
      whichFair: 'Which trade fair / which appointment / which event are you at?',
      visitingDay: 'Visiting Day',
      whichCompany: 'Which company is this lead for?',
      industry: 'Industry',
      companySize: 'Company size',
      postal_code: 'Postal code',
      which_postal_code: 'Company location postal code',
      country: 'Country',
      salutation: 'Salutation',
      title: 'Title',
      fName: 'First name',
      howFName: 'What is the first name?',
      lName: 'Last name',
      howLName: 'What is the last name?',
      status: 'Status',
      email: 'E-Mail',
      howEmail: 'What is the email address?',
      phone: 'Phone',
      howPhone: 'What is the phone number?',
      businessCard: 'Business card',
      bcFront: 'Business card front',
      bcBack: 'Business card back',
      priority: 'Priority',
      attachments: 'Attachments',
      attachment1: 'Attachment 1',
      attachment2: 'Attachment 2',
      followUp: 'Follow-up action',
      AddAdditional: 'Additional',
      note: 'Note',
      note_placeholder: 'Please enter comments here',
      success: 'The lead has been completed successfully!',
      additionalLead: 'Generate another lead',
      dashboard: 'Go to dashboard',
      backTo: 'Back to',
      continue: 'Continue to',
      finishLead: 'Finish lead',
      newLead: 'Create new Lead',
      savedLeads: 'Saved leads',
      noLeads: 'You have not yet saved any leads.',
      searchTerm: 'Search term',
      giveSearch: 'Enter your term here',
      delete: 'Delete',
      editLead: 'Edit Lead',
      noResult: 'The search returned no hits!',
      smallDelete: 'delete',
      from: 'from',
      youWant: 'Do you want to delete',
      really: 'for real?',
      cancel: 'Cancel',
    },
    wishlists: {
      wishlists: 'Watch lists',
      wishlist: 'Watch list',
      addWishlist: 'Add watch list',
      noWishlist: 'You have not yet created any watch lists.',
      delete: 'Delete',
      save: 'Save',
      youWant: 'Do you want to delete the watch list',
      forReal: 'for real?',
      cancel: 'Cancel',
      leadTransfer: 'Transfer in leads',
      transferAll: 'Select all for lead transfer',
      noProducts: 'You don\'t have any products on your watch list yet.',
      toFinder: 'To product finder',
      toDetail: 'To detail page',
      deleteProduct: 'Do you want to delete the product',
      reallyFrom: 'from',
      deleteSmall: 'will be deleted',
      deletedelete: 'for real?',
      add: 'add',
      newWishlist: 'new watch list',
      enterName: 'Please enter a name.',
      toWishlist: 'To watch list',
      from: 'from',
    },
    productFinder: {
      all: 'All',
      optionsFor: 'Options for',
      productFinder: 'Product finder',
      showResults: 'Show results',
      allFilters: 'Show all filters',
      products: 'Products',
      product: 'Product',
      quickView: 'Quick view',
      detailView: 'Detail view',
      filter: 'Filter',
      addWishlist: 'Add to watch list',
      toWishlist: 'to watch list?',
      yourWishlists: 'Your watch lists',
      manageWishlists: 'Manage watch lists',
      save: 'Save',
      show: 'Show',
      brochures: 'Leaflets',
      priceList: 'Price list',
      orderForms: 'Order forms',
      videos: 'Videos',
      training: 'Training',
      other: 'Other',
      noData: 'There are currently no files for this category.',
      width: 'Width',
      height: 'Height',
      weight: 'Weight',
      depth: 'Depth',
      voltage: 'Connection voltage',
      power: 'Power consumption',
      validation: 'Validation',
      compressedAir: 'Compressed air',
      airConsumption: 'Air consumption',
      spread: 'Spread',
      spreadTo: 'Spread to',
    },
    translation: {
      infoCode: 'About Code',
      context: 'The translations are used in the following context:',
      newEntry: 'New entry',
      free: 'Leave blank for new entry.',
      onlyTranslation: 'The code is only required if a translation is to be accepted.',
      translationExists: 'Translation is available',
      noTranslation: 'Translation is not available',
      createNewEntry: 'A new entry is created.',
      machine: 'Machine',
      create: ' Create',
      newImport: 'New import',
      search: 'Search',
      dropTitle: 'Choose your files',
      dropSubTitle: 'Files should be .csv format',
      dropText: 'Drag & drop your files here',
      dropList: 'Load files:',
      dropListRemoveAll: 'Remove all',
      dropListUpload: 'Open editor',
      dropErrorFormat: 'is not a valid format',
      dropErrorNoReference: 'There is no reference file.',
      dropErrorNoTranslation: 'There is no translation file.',
    },
    myPolyclip: {
      welcomeTo: 'Welcome to My Poly-clip',
      photo: 'The photos, graphics, videos, presentations, etc. available here may be used for advertising purposes for the company Poly-clip System\'s products, within the scope of our',
      terms: 'Terms of Service',
      copyright: '. In print and online publications, photos and graphics must be provided with the notice Copyright: Poly-clip System ®.',
      publication: 'Publication is free of charge. In the case of print media, we ask that you send us a specimen copy, and in the case of electronic media, a short message by e-mail.',
      download: 'By downloading files you confirm that you use our',
      reading: 'and have read and accepted.',
      welcome: 'Welcome to',
      loadingInfo: 'Loading My Poly-clip information ...',
      machine: 'Machine',
      machineNumber: 'Machine number',
      language: 'Language',
      searchTerm: 'Search term',
      giveSearchTerm: 'Give your search term here',
      search: 'Search',
      freeText: 'Free text search',
      detail: 'Detail search',
      searchName: 'Search for data name',
      reset: 'Reset filter',
      general: 'General',
      name: 'Name',
      fileType: 'File type',
      size: 'Size',
      wishlist: 'Watch list',
      library: 'My Poly-clip library',
      missingPermission: 'Missing permission',
      files: 'files',
      file: 'file',
      extended_filters: {
        machines: 'Machines',
        applications: 'Applications',
        consumables: 'Consumables',
        others: 'Others',
      },
    },


    dashboard_sales: {
      welcome: 'Welcome',
    },

    formSelect: {
      pleaseChoose: 'Please choose',
    },
  },
};
